import { TFunction } from "i18next";

interface IChipDetails {
  description: string;
  name: string;
}

const allChipDetails = (t: TFunction): Record<string, IChipDetails> => ({
  wildcard: {
    name: t("chips.wildcard.name", "Wildcard"),
    description: t(
      "chips.wildcard.description",
      "Make unlimited free transfers."
    ),
  },
  gosmall: {
    name: t("chips.gosmall.name", "Go Small"),
    description: t(
      "chips.gosmall.description",
      "All Back Court Players score double points."
    ),
  },
  gobig: {
    name: t("chips.gobig.name", "Go Big"),
    description: t(
      "chips.gobig.description",
      "All Front Court Players score double points."
    ),
  },
});

export const getChipName = (name: string, t: TFunction) =>
  allChipDetails(t)[name].name;

export const getChipDetails = (name: string, t: TFunction) =>
  allChipDetails(t)[name];

export const getAllChipDetails = (t: TFunction) => allChipDetails(t);

import i18n from "../i18n";
import { IWidgetParams, IWidgetResponse } from "../types";
import "./widget.css";

interface widgetAppIdType {
  [key: string]: string;
}
const widgetAppId: widgetAppIdType = {
  it: "NBA_FANTASY_ISM_IT",
  fr: "NBA_FANTASY_ISM_FR",
  es: "NBA_FANTASY_ISM_ES",
  de: "NBA_FANTASY_ISM_DE",
  el: "NBA_FANTASY_ISM_GR",
  en: "NBA_FANTASY_ISM_EN",
  he: "NBA_FANTASY_ISM_HE",
  pt: "NBA_FANTASY_ISM_BR",
  tr: "NBA_FANTASY_ISM_TR",
};
const successFromLogin = (response: IWidgetResponse) => {
  const responseCode = response.code;
  if (responseCode === "loginSuccess" || responseCode === "regSuccess") {
    const data = {
      uuid: response.data.userDetails.uuid,
      token: response.data.token,
    };
    fetch("/api/player/login/", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.text())
      .then((text) => {
        console.log("Success:", text);
        window.location.href = "/?login=success";
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
};

const successFromForgotPassword = (response: IWidgetResponse) => {
  console.log(response);
};

const successFromUpdate = (response: IWidgetResponse) => {
  const responseCode = response.code;
  if (responseCode === "userUpdateSuccess") {
    // Currently the only way for a user to get their details updated is
    // to logout and login again as we don't store there token which is
    // needed to fetch their new details - this does seem to be stored in
    // local storage (loginToken) so maybe we can use this?
    window.location.assign("/player/logout");
  }
};

const failureFromEvent = (response: any) => {};

const widgetStyle = {
  ForgotPassword: {
    styleButton: "WidgetButton",
    styleDropDown: "WidgetDropDown",
    styleDropDownError: "WidgetDropDown",
    styleFieldLabel: "WidgetFieldLabel",
    styleImage: "WidgetImage",
    styleSubContent: "WidgetSubContent",
    styleTextBox: "WidgetTextBox",
    styleTextBoxError: "WidgetTextBox",
  },
  LogIn: {
    styleButton: "WidgetButton",
    styleDropDown: "WidgetDropDown",
    styleDropDownError: "WidgetDropDown",
    styleFieldLabel: "WidgetFieldLabel",
    styleImage: "WidgetImage",
    styleSubContent: "WidgetSubContent",
    styleTextBox: "WidgetTextBox",
    styleTextBoxError: "WidgetTextBox",
  },
  SignUp: {
    styleButton: "WidgetButton",
    styleDropDown: "WidgetDropDown",
    styleDropDownError: "WidgetDropDown",
    styleFieldLabel: "WidgetFieldLabel",
    styleImage: "WidgetImage",
    styleSubContent: "WidgetSubContent",
    styleTextBox: "WidgetTextBox",
    styleTextBoxError: "WidgetTextBox",
  },
  UpdateProfile: {
    styleButton: "WidgetButton",
    styleDropDown: "WidgetDropDown",
    styleDropDownError: "WidgetDropDown",
    styleFieldLabel: "WidgetFieldLabel",
    styleImage: "WidgetImage",
    styleSubContent: "WidgetSubContent",
    styleTextBox: "WidgetTextBox",
    styleTextBoxError: "WidgetTextBox",
  },
};

export const showLogin = () => {
  const widgetParams: IWidgetParams = {
    modal: true,
    appId: widgetAppId[i18n.language],
    selector: "divPopup",
    cssStyle: widgetStyle,
  };
  if (process.env.REACT_APP_WIDGET_ENV) {
    widgetParams.env = process.env.REACT_APP_WIDGET_ENV;
  }
  const widget = window.UVWidget.Login.new(widgetParams);
  widget.OffAll();
  widget.On("onLoginSuccess", successFromLogin);
  widget.On("onLoginFailure", failureFromEvent);
  widget.On("onSignUpSuccess", successFromLogin);
  widget.Show();
};

export const showUpdateProfile = () => {
  const widgetParams: IWidgetParams = {
    modal: true,
    appId: widgetAppId[i18n.language],
    selector: "divPopup",
    cssStyle: widgetStyle,
  };
  if (process.env.REACT_APP_WIDGET_ENV) {
    widgetParams.env = process.env.REACT_APP_WIDGET_ENV;
  }
  const widget = window.UVWidget.UpdateProfile.new(widgetParams);
  widget.OffAll();
  widget.On("onUpdateProfileSuccess", successFromUpdate);
  widget.Show();
};

export const showSignup = () => {
  const widgetParams: IWidgetParams = {
    modal: true,
    appId: widgetAppId[i18n.language],
    selector: "divPopup",
    cssStyle: widgetStyle,
  };
  if (process.env.REACT_APP_WIDGET_ENV) {
    widgetParams.env = process.env.REACT_APP_WIDGET_ENV;
  }
  const widget = window.UVWidget.SignUp.new(widgetParams);
  widget.OffAll();
  widget.On("onLoginSuccess", successFromLogin);
  widget.On("onLoginFailure", failureFromEvent);
  widget.On("onSignUpSuccess", successFromLogin);
  widget.On("onSignUpFailure", failureFromEvent);
  widget.Show();
};

export const showForgotPassword = () => {
  const widgetParams: IWidgetParams = {
    modal: true,
    appId: widgetAppId[i18n.language],
    selector: "divPopup",
    cssStyle: widgetStyle,
  };
  if (process.env.REACT_APP_WIDGET_ENV) {
    widgetParams.env = process.env.REACT_APP_WIDGET_ENV;
  }
  const widget = window.UVWidget.ForgotPassword.new(widgetParams);
  widget.OffAll();
  widget.On("onForgotPasswordChange", successFromForgotPassword);
  widget.On("onForgotPasswordSuccess", successFromForgotPassword);
  widget.On("onForgotPasswordFailure", failureFromEvent);
  widget.Show();
};

import styled from "styled-components/macro";
import { PrimaryGradient } from "./Utils";

interface IProps {
  align?: "start" | "center";
}

const SubHeading = styled.h3`
  margin: 0 0 ${(props) => props.theme.space[3]};
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 3rem;
  font-weight: bold;
`;

export const BoldSubHeading = styled.h3<IProps>`
  ${PrimaryGradient}
  margin: 0;
  padding: ${(props) => props.theme.space[1]};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizes[0]};
  text-align: ${(props) => props.align || "start"};
`;

export default SubHeading;

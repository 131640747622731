import styled, { css } from "styled-components/macro";
import i18n from "../i18n";

const Title = styled.h2`
  margin: ${({ theme }) => theme.space[3]} 0;
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 6rem;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  ${i18n.language === "tr" &&
  css`
    text-transform: capitalize;
  `}
`;

export default Title;

import { navigate, RouteComponentProps } from "@reach/router";
import qs from "qs";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getPlayerData } from "../core/store/player/reducers";
import { IPlayer } from "../core/store/player/types";
import step1_1300 from "../img/home/step-1-1300.jpg";
import step1_650 from "../img/home/step-1-650.jpg";
import step2_1300 from "../img/home/step-2-1300.jpg";
import step2_650 from "../img/home/step-2-650.jpg";
import step3_1300 from "../img/home/step-3-1300.jpg";
import step3_650 from "../img/home/step-3-650.jpg";
import step4_1300 from "../img/home/step-4-1300.jpg";
import step4_650 from "../img/home/step-4-650.jpg";
import { Main, Wrapper } from "./Layout";
import Login from "./Login";
import Register from "./player/Register";
import SubHeading from "./SubHeading";
import Title from "./Title";
import { PrimaryGradient } from "./Utils";

// const HomeHeader = styled.div`
//   margin: 0 ${(props) => props.theme.space[2]};

//   @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
//     margin: 0 0 3rem;
//   }
// `;

const PromoWrapper = styled.div`
  ${PrimaryGradient}
  margin: 0 10px;
  padding: 2px;
  border-radius: ${(props) => props.theme.radii[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin: 0;
  }
`;

const Promo = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.theme.space[2]};

  border-radius: ${(props) => props.theme.radii[0]};
  color: ${(props) => props.theme.colors.offBlack};
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    padding: ${(props) => props.theme.space[4]};
  }
`;

const PromoMain = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(100% / 5 * 3);
  }
`;

const HomeSubHeading = styled(SubHeading)`
  font-family: ${(props) => props.theme.fonts.action};
  text-transform: uppercase;
  color: inherit;
  font-style: italic;
`;

const PromoCopy = styled.p``;

const Highlights = styled.div`
  margin: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const HighlightsItem = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(45%);
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
  }
`;

const StyledHomeStep = styled.div<IHomeStepInnerProps>`
  padding-top: 250px;
  background-image: url(${(props) => props.image1x});
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid ${(props) => props.theme.colors.red};
  border-radius: ${(props) => props.theme.radii[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-left: 320px;
    background-size: 340px 220px;
  }
`;

const HomeStepInner = styled.div<IHomeStepStyledProps>`
  padding: ${(props) => props.theme.space[4]};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radii[0]};
  color: ${(props) => props.theme.colors.offBlack};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex: 1;
    min-height: 220px;
    border-top-right-radius: ${(props) => props.theme.radii[1]};
    border-bottom-right-radius: ${(props) => props.theme.radii[1]};
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

interface IHomeStepStyledProps {
  isAlt?: boolean;
}
interface IHomeStepInnerProps {
  image1x: string;
  image2x: string;
}
interface IHomeStepProps {
  showButton?: boolean;
  content: string;
  heading: string;
}

type HomeStepProps = IHomeStepProps &
  IHomeStepStyledProps &
  IHomeStepInnerProps;

const HomeStep: React.FC<HomeStepProps> = ({
  showButton,
  content,
  heading,
  isAlt,
  image1x,
  image2x,
}) => {
  return (
    <StyledHomeStep image1x={image1x} image2x={image2x}>
      <HomeStepInner isAlt={isAlt}>
        <HomeSubHeading>{heading}</HomeSubHeading>
        <p>{content}</p>
      </HomeStepInner>
    </StyledHomeStep>
  );
};

interface IPropsFromState {
  player: IPlayer | null;
}

type Props = RouteComponentProps & IPropsFromState & WithTranslation;

class Home extends React.Component<Props> {
  public render() {
    const { t, player } = this.props;
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (query.login) {
      switch (query.login) {
        case "success":
          let url = "/";
          if (player && player.entry) {
            // Season start
            url = "/my-team";
          } else if (player && player.id) {
            url = "/squad-selection";
          }
          // Need this to make sure LocationProvider is ready
          setTimeout(() => {
            navigate(url, {
              replace: true,
            });
          }, 0);
          return null;
        default:
          break;
      }
    }

    return (
      <>
        {!player && (
          <Wrapper>
            <Main isWide={true}>
              <Title></Title>
              <PromoWrapper>
                <Promo>
                  <PromoMain>
                    <HomeSubHeading>
                      {t("home.promoHeading", "Sign up for nba fantasy")}
                    </HomeSubHeading>
                    <PromoCopy>
                      {t(
                        "home.promoCopyn",
                        "We're working on updates to NBA Fantasy ahead of next season."
                      )}
                    </PromoCopy>
                    <PromoCopy>
                      {t(
                        "home.promoCopyn2",
                        "Be the first to know when NBA Fantasy returns. Create or log-in to your account and opt-in to emails so you're ready for the new season."
                      )}
                    </PromoCopy>
                  </PromoMain>
                  <Login />
                </Promo>
              </PromoWrapper>
            </Main>
          </Wrapper>
        )}

        {player && player.id ? (
          <Register />
        ) : (
          <Wrapper>
            <Main isWide={true}>
              <Highlights>
                <HighlightsItem>
                  <HomeStep
                    showButton={!(player && player.id)}
                    heading={t("home.step1Headingn", "Build your roster")}
                    content={t(
                      "home.step1Contentn",
                      "Pick your 10 player NBA roster using your $100m budget."
                    )}
                    image1x={step1_650}
                    image2x={step1_1300}
                  />
                </HighlightsItem>
                <HighlightsItem>
                  <HomeStep
                    heading={t("home.step2Heading", "Create Leagues")}
                    content={t(
                      "home.step2Content",
                      "Create leagues and invite your friends to compete throughout the season."
                    )}
                    image1x={step2_650}
                    image2x={step2_1300}
                  />
                </HighlightsItem>
              </Highlights>
              <Highlights>
                <HighlightsItem>
                  <HomeStep
                    heading={t("home.step3Heading", "Set your line-up")}
                    content={t(
                      "home.step3Contentn",
                      "Choose your line-up for the next gameday and watch your starting 5 score points as they take to the court."
                    )}
                    image1x={step3_650}
                    image2x={step3_1300}
                  />
                </HighlightsItem>
                <HighlightsItem>
                  <HomeStep
                    heading={t("home.step4Headingn", "Sign and drop players")}
                    content={t(
                      "home.step4Contentn",
                      "Sign and waive players through the season to improve your team then follow along to see your team score points. "
                    )}
                    image1x={step4_650}
                    image2x={step4_1300}
                  />
                </HighlightsItem>
              </Highlights>
            </Main>
          </Wrapper>
        )}
      </>
    );
  }
}

export { Home as HomeTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    player: getPlayerData(state),
  };
};

export default withTranslation()(connect(mapStateToProps)(Home));

import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import {
  getEntry,
  getPrivateClassicLeaguesForEntry,
  getPublicClassicLeaguesForEntry,
  getSystemClassicLeaguesForEntry,
} from "../core/store/entries/reducers";
import { fetchEntrySummary } from "../core/store/entries/thunks";
import { IEntry, ILeagueEntry } from "../core/store/entries/types";
import { getTotalPlayers } from "../core/store/game/reducers";
import { fetchMyTeam } from "../core/store/my-team/thunks";
import { ITransferState } from "../core/store/my-team/types";
import { getPlayerData } from "../core/store/player/reducers";
import { getTransferState } from "../core/store/squad/reducers";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeam, ITeamsById } from "../core/store/teams/types";
import { fetchURL } from "../core/store/urls/thunks";
import { integerToMoney } from "../core/utils/money";
import i18n from "../i18n";
import { integerToDecimal } from "../utils/decimal";
import Badge from "./Badge";
import BoldLink from "./BoldLink";
import ButtonLink from "./ButtonLink";
import Footnote from "./Footnote";
import Movement from "./leagues/Movement";
import { LeagueSummaryTable } from "./leagues/Styles";
import Link from "./Link";
import Panel from "./Panel";
import { BoldSubHeading } from "./SubHeading";
import Table from "./Table";
import { BoxWrap, VisuallyHidden } from "./Utils";

const EntryName = styled.h3`
  margin: ${(props) => `0 ${props.theme.space[2]} ${props.theme.space[3]}`};
  font-size: ${(props) => props.theme.fontSizes[2]};
  font-weight: bold;
`;

const TeamName = styled.h2`
  margin: ${(props) => `0 ${props.theme.space[2]} ${props.theme.space[3]}`};
  padding-top: ${(props) => props.theme.space[3]};
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  ${i18n.language === "tr" &&
  css`
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  `}
`;

const DataList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const DataListItem = styled.li`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]} 0;
  border-bottom: 1px solid #d8d8d8;
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

const DataListHeading = styled.h5`
  flex: 1 0 65%;
  margin: 0;
  padding: 0 ${({ theme }) => theme.space[3]};
  font-size: ${(props) => props.theme.fontSizes[1]};
  font-weight: normal;
`;

const DataListValue = styled.div`
  flex: 1 0 35%;
  padding: 0 ${({ theme }) => theme.space[2]};
  font-size: ${(props) => props.theme.fontSizes[2]};
  text-align: end;
`;

const BadgeWrapper = styled.div`
  text-align: center;
`;

const TableFixed = styled(Table)`
  table-layout: fixed;
`;

const MovementCell = styled.td`
  width: 16%;
`;

const Rank = styled.td`
  width: 30%;
  font-weight: 800;
`;

const Name = styled.td`
  width: 54%;
  overflow-wrap: break-word;
  hyphens: auto;
`;

const linkToLeague = (l: ILeagueEntry) =>
  `/leagues/${l.id}/standings/${l.scoring}`;

const boldStyles = css`
  color: inherit;
  font-weight: bold;
`;

const BoldText = styled.span`
  ${boldStyles}
`;

const StyledLink = styled(Link)`
  ${boldStyles}
`;

const StyledTdFixedWidth = styled.td`
  width: 33%;
`;

const MovementHead = styled.th`
  width: 16%;
`;

const RankHead = styled.th`
  width: 30%;
`;

const NameHead = styled.th`
  width: 54%;
`;

interface ILeagueSummaryProps {
  title: React.ReactNode;
  leagues: ILeagueEntry[];
}

interface IRowProps {
  leagueEntry: ILeagueEntry;
}

const LeagueSummary: React.FC<ILeagueSummaryProps> = ({ leagues, title }) => (
  <Box mt={3}>
    <BoldSubHeading as="h5">{title}</BoldSubHeading>
    <LeagueSummaryTable>
      <thead>
        <tr>
          <MovementHead scope="col">
            <VisuallyHidden>Movement</VisuallyHidden>
          </MovementHead>
          <RankHead scope="col">Rank</RankHead>
          <NameHead scope="col">League</NameHead>
        </tr>
      </thead>
      <tbody>
        {leagues.map((l) => (
          <LeagueSummaryRow key={l.id} leagueEntry={l} />
        ))}
      </tbody>
    </LeagueSummaryTable>
  </Box>
);

const LeagueSummaryRow: React.FC<IRowProps> = ({ leagueEntry }) => (
  <tr>
    <MovementCell>
      <Movement
        lastRank={leagueEntry.entry_last_rank}
        rank={leagueEntry.entry_rank}
      />
    </MovementCell>
    <Rank>
      {leagueEntry.entry_rank ? leagueEntry.entry_rank.toLocaleString() : null}
    </Rank>
    <Name>
      <StyledLink to={linkToLeague(leagueEntry)}>{leagueEntry.name}</StyledLink>
    </Name>
  </tr>
);

interface IFanLeagueData {
  league: ILeagueEntry | null;
  team: ITeam | null;
}

interface IPropsFromState {
  entry: IEntry | null;
  mine: Boolean;
  privateClassicLeagues: ILeagueEntry[];
  publicClassicLeagues: ILeagueEntry[];
  systemClassicLeagues: ILeagueEntry[];
  teamsById: ITeamsById;
  totalPlayers: number;
  transfersState: ITransferState | null;
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
  fetchMyTeam: () => void;
  fetchUrl: (url: string) => void;
}

interface IOwnProps {
  entryId: number;
}

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps & WithTranslation;

class Entry extends React.Component<Props, IState> {
  public state: IState = { favTeamArticle: null };

  public componentDidMount() {
    if (this.props.mine && !this.props.transfersState) {
      this.props.fetchMyTeam();
    }
    if (!this.props.entry) {
      this.props.fetchEntrySummary(this.props.entryId);
    }
  }

  public render() {
    const {
      entry,
      privateClassicLeagues,
      publicClassicLeagues,
      systemClassicLeagues,
      teamsById,
      totalPlayers,
      transfersState,
      mine = false,
      t,
    } = this.props;

    if (!entry) {
      return null;
    }

    // Extract any fan league
    const fanLeagueData: IFanLeagueData = {
      league: null,
      team: null,
    };
    const fanLeagueMatch = /^team-(\d+)$/;

    // Please note the "!" non-null assertion operator on the filter
    const fanLeagues = systemClassicLeagues.filter((l) =>
      l.short_name!.match(fanLeagueMatch)
    );
    if (fanLeagues.length) {
      fanLeagueData.league = fanLeagues[0];
      fanLeagueData.team =
        teamsById[fanLeagues[0].short_name!.match(fanLeagueMatch)![1]];
    }

    // Extract any broadcaster leagues
    const broadcasterLeagueMatch = /^brd-/;
    // Please note the "!" non-null assertion operator on the filter
    const broadcasterLeagues: ILeagueEntry[] = systemClassicLeagues.filter(
      (l) => l.short_name!.match(broadcasterLeagueMatch)
    );
    const filteredSystemClassicLeagues = systemClassicLeagues.filter(
      (l) => !broadcasterLeagues.length || broadcasterLeagues.indexOf(l) === -1
    );

    return (
      <BoxWrap>
        <TeamName>{entry.name}</TeamName>
        <EntryName>
          {entry.player_first_name} {entry.player_last_name}
        </EntryName>
        <Box mt={2} pb={4}>
          <Panel>
            <Panel.Header
              title={t("myTeam.pointsRankings", "Points/Rankings")}
            />
            <Panel.Body>
              <DataList>
                <DataListItem>
                  <DataListHeading>
                    {t("myTeam.overallPoints", "Overall Points")}
                  </DataListHeading>
                  <DataListValue>
                    {integerToDecimal(entry.summary_overall_points || 0, 10)}
                  </DataListValue>
                </DataListItem>
                <DataListItem>
                  <DataListHeading>
                    {t("myTeam.overallRank", "Overall Rank")}
                  </DataListHeading>
                  <DataListValue>
                    {entry.summary_overall_rank &&
                      entry.summary_overall_rank.toLocaleString()}
                  </DataListValue>
                </DataListItem>
                <DataListItem>
                  <DataListHeading>
                    {t("myTeam.totalPlayers", "Total Players")}
                  </DataListHeading>
                  <DataListValue>{totalPlayers.toLocaleString()}</DataListValue>
                </DataListItem>
                <DataListItem>
                  <DataListHeading>
                    {t("myTeam.roundPointsn", "Gameday Fantasy Points")}
                  </DataListHeading>
                  <DataListValue>
                    {integerToDecimal(entry.summary_event_points || 0, 10)}
                  </DataListValue>
                </DataListItem>
              </DataList>
            </Panel.Body>
            <Panel.Footer>
              <BoldLink to={`/entry/${entry.id}/history`} color="blueDark">
                {t("myTeam.viewRoundHistory", "View Gameday History")}
              </BoldLink>
            </Panel.Footer>
          </Panel>
        </Box>
        {fanLeagueData.league && fanLeagueData.team && (
          <Box pb={4}>
            <Panel>
              <Panel.Header title={t("myTeam.fanLeague", "Fan League")} />
              <Panel.Body>
                <BadgeWrapper>
                  <Badge teamId={fanLeagueData.team.id} width="180px" />
                </BadgeWrapper>
              </Panel.Body>
              <Panel.Footer>
                <BoldLink to={linkToLeague(fanLeagueData.league)}>
                  {t("MyTeam.fanLeagueLink", "View {{team}} Fan League", {
                    team: fanLeagueData.team.name,
                  })}
                </BoldLink>
              </Panel.Footer>
            </Panel>
          </Box>
        )}

        <Box pb={4}>
          <Panel>
            <Panel.Header title={t("myTeam.leagues", "Leagues")} />
            <Panel.Body>
              <LeagueSummary
                title={t("myTeam.globalLeagues", "Global Leagues")}
                leagues={filteredSystemClassicLeagues}
              />
            </Panel.Body>
          </Panel>
        </Box>

        {broadcasterLeagues.length > 0 && (
          <Box pb={4}>
            <Panel>
              <Panel.Header
                title={t("myTeam.sponsorLeague", "Sponsor League")}
              />
              <Panel.Body>
                <TableFixed size="lg">
                  <tbody>
                    {broadcasterLeagues.map((l) => (
                      <LeagueSummaryRow key={l.id} leagueEntry={l} />
                    ))}
                  </tbody>
                </TableFixed>
              </Panel.Body>
            </Panel>
          </Box>
        )}

        {(privateClassicLeagues.length > 0 ||
          publicClassicLeagues.length > 0) && (
          <Box pb={4}>
            <Panel>
              <Panel.Header
                title={t("myTeam.classicLeagues", "Classic Leagues")}
              />
              <Panel.Body>
                <TableFixed size="lg">
                  <tbody>
                    {privateClassicLeagues.map((l) => (
                      <LeagueSummaryRow key={l.id} leagueEntry={l} />
                    ))}
                    {publicClassicLeagues.map((l) => (
                      <LeagueSummaryRow key={l.id} leagueEntry={l} />
                    ))}
                  </tbody>
                </TableFixed>
              </Panel.Body>
            </Panel>
          </Box>
        )}

        <Box pb={4} px={2}>
          <ButtonLink to="/leagues" fullwidth="true">
            {t("myTeam.createAndJoinLeagues", "Create and Join Leagues")}
          </ButtonLink>
        </Box>

        {mine && transfersState && (
          <Box pb={4}>
            <Panel>
              <Panel.Header
                title={t(
                  "myTeam.trans.transfersAndFinance",
                  "Transfers And Finance"
                )}
              />
              <Panel.Body>
                <Table size="lg">
                  <tbody>
                    <tr>
                      <td>
                        {t("myTeam.roundTransfers", "Gameweek transfers")}:
                      </td>
                      <StyledTdFixedWidth>
                        <BoldText>{transfersState.made}</BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                    <tr>
                      <td>{t("myTeam.totalTransfers", "Total transfers")}:</td>
                      <StyledTdFixedWidth>
                        <BoldText>
                          {entry.last_deadline_total_transfers}
                        </BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                  </tbody>
                </Table>
              </Panel.Body>
              <Panel.Footer>
                <BoldLink to={`/entry/${entry.id}/transfers`}>
                  {t("myTeam.viewTransferHistory", "View transfer history")}
                </BoldLink>
              </Panel.Footer>
              <Panel.Body>
                <Table size="lg">
                  <tbody>
                    <tr>
                      <td>{t("myTeam.squadValue", "Roster value")}:</td>
                      <StyledTdFixedWidth>
                        <BoldText>
                          {integerToMoney(transfersState.value, 10)}
                        </BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                    <tr>
                      <td>{t("myTeam.inTheBank", "In the bank")}:</td>
                      <StyledTdFixedWidth>
                        <BoldText>
                          {integerToMoney(transfersState.bank, 10)}
                        </BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                  </tbody>
                </Table>
              </Panel.Body>
            </Panel>
          </Box>
        )}
        {!mine && (
          <Box pb={4}>
            <Panel>
              <Panel.Header
                title={
                  <>
                    {t("myTeam.transfersAndFinance", "Transfers and Finance")}{" "}
                    <sup>*</sup>
                  </>
                }
              />
              <Panel.Body>
                <p />
                <ul>
                  <DataListItem>
                    <DataListHeading>
                      {t("myTeam.totalTransfers", "Total transfers")}:
                    </DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_total_transfers}
                    </DataListValue>
                  </DataListItem>
                </ul>
              </Panel.Body>
              <Panel.Footer>
                <StyledLink to={`/entry/${entry.id}/transfers`}>
                  {t("myTeam.viewTransferHistory", "View transfer history")}
                </StyledLink>
              </Panel.Footer>
              <Panel.Body>
                <ul>
                  <DataListItem>
                    <DataListHeading>
                      {t("myTeam.squadValue", "Roster value")}:
                    </DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_value !== null &&
                      entry.last_deadline_bank !== null
                        ? `${integerToMoney(
                            entry.last_deadline_value -
                              entry.last_deadline_bank,
                            10
                          )}`
                        : "-"}
                    </DataListValue>
                  </DataListItem>
                  <DataListItem>
                    <DataListHeading>
                      {t("myTeam.inTheBank", "In the bank")}:
                    </DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_bank !== null
                        ? `${integerToMoney(entry.last_deadline_bank, 10)}`
                        : "-"}
                    </DataListValue>
                  </DataListItem>
                </ul>
                <Box mt={1} px={2}>
                  <Footnote>* Values at the last deadline</Footnote>
                </Box>
              </Panel.Body>
            </Panel>
          </Box>
        )}
        {mine && (
          <Box pb={4}>
            <Panel>
              <Panel.Header title={t("myTeam.admin", "Admin")} />
              <Panel.Body>
                {mine && (
                  <Box p={1}>
                    <Box p={1}>
                      <ButtonLink to={`/entry-update`} fullwidth="true">
                        {t("teamDetails", "Team Details")}
                      </ButtonLink>
                    </Box>
                    <Box p={1}>
                      <ButtonLink to={`/player`} fullwidth="true">
                        {t("userProfile", "User profile")}
                      </ButtonLink>
                    </Box>
                  </Box>
                )}
              </Panel.Body>
            </Panel>
          </Box>
        )}
      </BoxWrap>
    );
  }
}

export { Entry as EntryTest };

interface IState {
  favTeamArticle: any | null;
}

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): IPropsFromState => {
  const player = getPlayerData(state);
  const entryId = ownProps.entryId;
  return {
    entry: getEntry(state, entryId),
    mine: Boolean(player && player.entry && player.entry === entryId),
    privateClassicLeagues: getPrivateClassicLeaguesForEntry(state, entryId),
    publicClassicLeagues: getPublicClassicLeaguesForEntry(state, entryId),
    systemClassicLeagues: getSystemClassicLeaguesForEntry(state, entryId),
    teamsById: getTeamsById(state),
    transfersState: getTransferState(state),
    totalPlayers: getTotalPlayers(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchMyTeam: () => dispatch(fetchMyTeam()),
  fetchUrl: (url) => dispatch(fetchURL(url)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Entry)
);

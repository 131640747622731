import { hideVisually, rgba, size } from "polished";
import * as React from "react";
import styled, { css } from "styled-components/macro";
import i18n from "../i18n";
import search from "../img/icons/search.svg";
import select from "../img/icons/select.svg";
import theme from "../theme";
import { isRTL } from "../utils/locale";

console.log(theme);
export const FieldWrap = styled.div`
  margin-bottom: ${theme.space[4]};
`;

interface ILabelProps {
  labelVisbility?: boolean;
}

export const FieldLabel = styled.label<ILabelProps>`
  display: block;
  margin-bottom: ${theme.space[1]};
  cursor: pointer;

  ${({ labelVisbility }) => labelVisbility === false && hideVisually};
`;

const Hint = styled.span`
  margin-bottom: ${theme.space[1]};
  display: block;
  color: ${theme.colors.offBlack};
  font-weight: 100;
  font-size: 14px;
`;

interface InputProps {
  hasErrors?: boolean;
  required?: boolean;
}

const InputWrap = styled.div`
  position: relative;
  padding-bottom: 4px;

  ::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 4px;
    border-bottom-right-radius: ${(props) => props.theme.radii[0]};
    border-bottom-left-radius: ${(props) => props.theme.radii[0]};
    background-color: ${(props) => props.theme.colors.yellow};
  }
`;

const FieldStyles = css`
  display: block;
  width: 100%;
  padding: ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => rgba(props.theme.colors.greyBlue, 0.1)};
  border-top-left-radius: ${(props) => props.theme.radii[0]};
  border-top-right-radius: ${(props) => props.theme.radii[0]};
  background-color: #efefef;
  color: #000;
  font-size: 16px;
`;

export const Input = styled.input<InputProps>`
  ${FieldStyles}
  ${(props) =>
    props.hasErrors &&
    css`
      border-color: ${theme.colors.error};

      &:focus {
        border-color: ${theme.colors.error};
      }
    `}
`;

export const SelectWrap = styled.div`
  position: relative;
  z-index: ${theme.zIndex.select.wrap};
  border-top-left-radius: ${(props) => props.theme.radii[0]};
  border-top-right-radius: ${(props) => props.theme.radii[0]};
  background-color: #efefef;

  ::after {
    content: "";
    position: absolute;
    background: url(${select}) no-repeat;
    top: 50%;
    transform: translateY(-50%);
    /* right: 10px; */
    ${size(8, 15)};
    background-size: contain;
    ${isRTL(i18n.language)
      ? css`
          left: 10px;
        `
      : css`
          right: 10px;
        `}
  }
`;

// Unable to extend SelectWrap for some reason
const InlineSelectWrap = styled.div`
  position: relative;
  display: inline-block;
  z-index: ${theme.zIndex.select.wrap};
  border: grey;

  ::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
    /* Triangle */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: blue;
    ${isRTL(i18n.language)
      ? css`
          left: 10px;
        `
      : css`
          right: 10px;
        `}
  }
`;

export const Select = styled.select`
  ${FieldStyles}
  position: relative;
  z-index: ${theme.zIndex.select.select};
  background-color: transparent;
  appearance: none;
  cursor: pointer;

  ::-ms-expand {
    display: none; /* [1] */
  }
`;

const InlineSelect = styled(Select)`
  display: inline-block;
  width: auto;
  ${isRTL(i18n.language)
    ? css`
        padding-left: 40px;
      `
    : css`
        padding-right: 40px;
      `}
`;

const SearchInput = styled(Input)`
  ${isRTL(i18n.language)
    ? css`
        padding-right: 45px;
      `
    : css`
        padding-left: 45px;
      `}

  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: textfield;
`;

const SearchIcon = styled.span`
  ${size("32px")}
  position: absolute;
  top: 50%;
  left: ${(props) => props.theme.space[2]};
  border-radius: 50%;
  transform: translateY(-50%);
  background: ${(props) => props.theme.colors.yellow} url(${search}) no-repeat
    center;
  background-size: 16px 16px, auto;
  ${isRTL(i18n.language)
    ? css`
        right: ${(props) => props.theme.space[2]};
      `
    : css`
        left: ${(props) => props.theme.space[2]};
      `}
`;

export const FieldCols = styled.div`
  position: relative;
  z-index: 1;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
  }
`;

export const FieldCol = styled.div`
  padding-right: ${({ theme }) => theme.space[2]};
  padding-left: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 1;
  }
`;

interface ILabelHintProps {
  hint?: React.ReactNode;
  id: string;
  label: React.ReactNode;
  labelVisbility?: boolean;
}

interface IFieldProps extends ILabelHintProps {
  name?: string;
  value?: string | number;
}

interface IInputProps extends IFieldProps {
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  disabled?: boolean;
  readonly?: boolean;
  hasErrors?: boolean;
  required?: boolean;
}

export const LabelHint: React.FC<ILabelHintProps> = ({
  id,
  label,
  hint,
  labelVisbility = true,
}) => (
  <FieldLabel htmlFor={id} labelVisbility={labelVisbility}>
    <span>{label}</span>
    {hint && <Hint>{hint}</Hint>}
  </FieldLabel>
);

const InlineLabel: React.FC<ILabelHintProps> = ({
  id,
  label,
  labelVisbility = true,
}) => (
  <FieldLabel htmlFor={id} labelVisbility={labelVisbility}>
    <span>{label}</span>
  </FieldLabel>
);

export const InputField: React.FC<IInputProps> = ({
  hint,
  id,
  label,
  maxLength,
  name,
  onBlur,
  onChange,
  type,
  value,
  required = true,
  hasErrors,
  disabled = false,
  readonly = false,
  labelVisbility = true,
}) => (
  <>
    <LabelHint
      id={id}
      label={label}
      hint={hint}
      labelVisbility={labelVisbility}
    />
    <InputWrap>
      <Input
        required={required}
        type={type}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        maxLength={maxLength}
        disabled={disabled}
        readOnly={readonly}
        hasErrors={hasErrors}
      />
    </InputWrap>
  </>
);

interface ISearchProps extends IFieldProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchField: React.FC<ISearchProps> = ({
  hint,
  id,
  label,
  name,
  onChange,
  value,
  ...rest
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} />
    <InputWrap>
      <SearchIcon />
      <SearchInput
        type="search"
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        {...rest}
      />
    </InputWrap>
  </>
);

interface INumberProps extends IFieldProps {
  ariaValueMax?: number;
  ariaValueMin?: number;
  ariaValueNow?: number;
  max?: string | number;
  min?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  step?: string | number;
}

export const NumberField: React.FC<INumberProps> = ({
  ariaValueMax,
  ariaValueMin,
  ariaValueNow,
  hint,
  id,
  label,
  max = "",
  min = "",
  name,
  onChange,
  step = "",
  value,
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} />
    <Input
      aria-valuemax={ariaValueMax}
      aria-valuemin={ariaValueMin}
      aria-valuenow={ariaValueNow}
      type="number"
      id={id}
      max={max}
      min={min}
      name={name}
      onChange={onChange}
      step={step}
      value={value}
    />
  </>
);

const Checkbox = styled.input`
  ${hideVisually()};

  :hover + span::before {
    border-color: black;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="75" height="75"><path d="M28.051 68.625c-1.199 0-2.324-.449-3.149-1.273L5.254 47.102c-1.875-1.727-1.949-4.648-.149-6.523 1.727-1.875 4.649-1.949 6.524-.148l14.398 10.273c.825.75 2.102.602 2.774-.301L62.926 8.25c1.5-2.023 4.426-2.477 6.449-.898 2.023 1.5 2.477 4.425.898 6.449L31.727 66.75c-.825 1.051-2.024 1.727-3.301 1.801-.153.074-.227.074-.375.074z" fill="%23E8E8E8"/></svg>');
  }

  :focus + span {
    outline: -webkit-focus-ring-color auto 5px;
  }

  :checked + span::before {
    background-color: black;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="75" height="75"><path d="M28.051 68.625c-1.199 0-2.324-.449-3.149-1.273L5.254 47.102c-1.875-1.727-1.949-4.648-.149-6.523 1.727-1.875 4.649-1.949 6.524-.148l14.398 10.273c.825.75 2.102.602 2.774-.301L62.926 8.25c1.5-2.023 4.426-2.477 6.449-.898 2.023 1.5 2.477 4.425.898 6.449L31.727 66.75c-.825 1.051-2.024 1.727-3.301 1.801-.153.074-.227.074-.375.074z" fill="white" /></svg>');
  }
`;

interface ICheckboxText {
  hasLogo?: boolean;
}

const CheckboxText = styled.span<ICheckboxText>`
  display: inline-block;
  position: relative;
  padding: 6px 0 6px 42px;
  line-height: 1.6;

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    border: 1px solid black;
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: middle;
  }

  ${({ hasLogo }) =>
    hasLogo &&
    css`
      display: flex;
      align-items: center;
    `}
`;

interface ICheckboxProps {
  checked?: boolean;
  id: string;
  label: React.ReactNode;
  name?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  logo?: any;
  hideLabel?: boolean;
}

const LogoImg = styled.img`
  height: 29px;
  width: auto;
  max-width: 100%;
`;

export const CheckboxField: React.SFC<ICheckboxProps> = ({
  id,
  onChange,
  label,
  checked,
  logo,
  hideLabel = false,
}) => {
  let labelStyles = {};
  if (hideLabel) {
    labelStyles = hideVisually;
  }

  return (
    <div>
      <label htmlFor={id}>
        <Checkbox
          type="checkbox"
          id={id}
          role="checkbox"
          checked={checked}
          aria-checked={checked}
          onChange={onChange}
        />
        <CheckboxText hasLogo={logo}>
          {hideLabel === false && <span style={labelStyles}>{label}</span>}
          {logo && <LogoImg src={logo} />}
        </CheckboxText>
      </label>
    </div>
  );
};

CheckboxField.defaultProps = {
  checked: false,
};

export const Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
`;

interface ILabelLegendProps {
  children: React.ReactNode;
  legend: string;
}

export const LabelLegend: React.SFC<ILabelLegendProps> = ({
  children,
  legend,
}) => (
  <Fieldset>
    <legend>
      <span>{legend}</span>
    </legend>
    {children}
  </Fieldset>
);

const Radio = styled.input`
  ${hideVisually()};

  &:hover + span::before {
    background-color: #e5e5e5;
    box-shadow: 0 0 0 0.2rem blue;
  }

  &:focus + span {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:checked + span::before {
    box-shadow: 0 0 0 0.2rem blue;
    background-color: blue;
  }
`;

const RadioText = styled.span`
  display: inline-block;
  position: relative;
  padding: 6px 0 6px 42px;
  line-height: 1.6;

  &::before {
    /* [1] */
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 23px;
    height: 23px;
    transform: translateY(-50%);
    border: 5px solid white;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #e5e5e5;
    content: "";
    vertical-align: middle;
  }
`;

const StyledFieldFeedback = styled.p`
  color: ${theme.colors.error};
  margin-top: 0;
`;

interface IOptions {
  checked?: boolean;
  id: string;
  label: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  value: string;
}
interface IRadioGroupProps {
  legend: React.ReactNode;
  name: string;
  options: IOptions[];
}

export const RadioGroup: React.SFC<IRadioGroupProps> = ({
  legend,
  name,
  options,
}) => (
  <Fieldset>
    <legend>
      <FieldLabel>{legend}</FieldLabel>
    </legend>
    {options.map((o) => (
      <div key={o.id}>
        <label htmlFor={o.id}>
          <Radio
            type="radio"
            id={o.id}
            name={name}
            value={o.value}
            role="radio"
            aria-checked={o.checked}
            onChange={o.onChange}
            checked={o.checked}
          />
          <RadioText>{o.label}</RadioText>
        </label>
      </div>
    ))}
  </Fieldset>
);

interface ISelectProps extends IFieldProps {
  children: React.ReactNode;
  onBlur?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  labelVisbility?: boolean;
}

export const SelectField: React.FC<ISelectProps> = ({
  children,
  hint,
  id,
  label,
  onBlur,
  onChange,
  value,
  labelVisbility = true,
  required = true,
}) => (
  <>
    <LabelHint
      id={id}
      label={label}
      hint={hint}
      labelVisbility={labelVisbility}
    />
    <InputWrap>
      <SelectWrap>
        <Select id={id} onBlur={onBlur} onChange={onChange} value={value}>
          {children}
        </Select>
      </SelectWrap>
    </InputWrap>
  </>
);

export const InlineSelectField: React.FC<ISelectProps> = ({
  children,
  id,
  label,
  onChange,
  value,
}) => (
  <>
    <InlineLabel id={id} label={label} />
    <InlineSelectWrap>
      <InlineSelect id={id} onChange={onChange} value={value}>
        {children}
      </InlineSelect>
    </InlineSelectWrap>
  </>
);

interface IFieldFeedback {
  children: React.ReactNode;
}

export const FieldFeedback: React.FC<IFieldFeedback> = ({ children }) => (
  <StyledFieldFeedback>{children}</StyledFieldFeedback>
);

import { ellipsis } from "polished";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getElementTypesById } from "../core/store/element-types/reducers";
import { getElement } from "../core/store/elements/reducers";
import Badge from "./Badge";
import Media from "./Media";
import TeamCode from "./TeamCode";

const MenuButton = styled.button`
  ${ellipsis()};
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: black;
  font-family: inherit;
  text-align: left;
  line-height: 1;
  cursor: pointer;
`;

const Name = styled.div`
  :hover {
    text-decoration: underline;
  }
`;

const FirstName = styled.span`
  ${ellipsis()};
`;

const SecondName = styled.span`
  ${ellipsis()};
  font-weight: 500;
`;

interface IStyledElementType {
  elementTypeId: number;
}

const StyledElementType = styled.p<IStyledElementType>`
  display: inline-block;
  margin: ${(props) => `0 0 0 ${props.theme.space[2]}`};
  color: ${(props) =>
    props.theme.colors.elementTypes[props.elementTypeId].colors[0]};
  font-weight: 300;
  font-style: italic;
  font-size: 1.3rem;
  text-decoration: none;
`;

interface IProps {
  elementId: number;
  renderElementMenu: () => void;
}

const ElementInTable: React.FC<IProps> = ({ elementId, renderElementMenu }) => {
  const { t } = useTranslation();
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );
  const elementTypesById = useSelector(getElementTypesById);

  if (!element) {
    return null;
  }

  const elementType = elementTypesById[element.element_type];

  return (
    <MenuButton onClick={renderElementMenu}>
      <Media img={<Badge teamId={element.team} width="40px" />} centred={true}>
        <Name>
          <div>
            <FirstName>{element.first_name}</FirstName>
          </div>
          <div>
            <SecondName>{element.second_name}</SecondName>
          </div>
        </Name>
        <div>
          <TeamCode isSmall={true} teamId={element.team} />
          <StyledElementType
            elementTypeId={elementType.id}
            title={elementType.singular_name}
          >
            {elementType.singular_name_short === "FC"
              ? t("elementType.frontcourtShort", "{{short}}", {
                  short: elementType.singular_name_short,
                })
              : t("elementType.backcourtShort", "{{short}}", {
                  short: elementType.singular_name_short,
                })}
          </StyledElementType>
        </div>
      </Media>
    </MenuButton>
  );
};

export default ElementInTable;

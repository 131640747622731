import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import BackCourtBg from "../../img/back-court-bg.png";
import FrontCourtBg from "../../img/front-court-bg.png";
import { BoxWrapStyles } from "../Utils";

const StyledElementGroup = styled.div<IProps>`
  ${BoxWrapStyles}
  padding: 0 0.4rem 0.8rem;
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.elementTypes[props.elementTypeId].colors[0]}
      75%,
    ${(props) => props.theme.colors.elementTypes[props.elementTypeId].colors[1]}
  );
`;

const ElementGroupHeading = styled.h3<IProps>`
  margin: 0;
  padding: ${(props) => props.theme.space[1]};
  background-color: ${(props) => props.theme.colors.greyBlue};
  color: black;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizes[3]};
  font-weight: bold;
  text-transform: uppercase;

  ${(props) =>
    props.elementTypeId &&
    css`
      background-image: linear-gradient(
        to right,
        ${props.theme.colors.elementTypes[props.elementTypeId].colors[0]} 75%,
        ${props.theme.colors.elementTypes[props.elementTypeId].colors[1]}
      );
      color: white;
    `};
`;

const ElementGroupBody = styled.div<IProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: ${(props) => props.theme.space[2]};
  background-color: #fff;
  background-repeat: no-repeat;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    ${(props) =>
      props.elementTypeId === 1 &&
      css`
        background-image: url(${BackCourtBg});
        background-position: top right;
      `};

    ${(props) =>
      props.elementTypeId === 2 &&
      css`
        background-image: url(${FrontCourtBg});
        background-position: bottom left;
      `};
  }
`;
interface IProps {
  elementTypeId: number;
}

const ElementGroup: React.FC<IProps> = ({ children, elementTypeId = 0 }) => {
  const elementTypesById = useSelector(getElementTypesById);
  const elementType = elementTypesById[elementTypeId];
  return (
    <StyledElementGroup elementTypeId={elementTypeId}>
      {elementTypeId && (
        <ElementGroupHeading elementTypeId={elementTypeId}>
          {elementType.plural_name}
        </ElementGroupHeading>
      )}
      <ElementGroupBody elementTypeId={elementTypeId}>
        {children}
      </ElementGroupBody>
    </StyledElementGroup>
  );
};

export default ElementGroup;

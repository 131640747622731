import { format } from "date-fns";
import { rgba } from "polished";
import * as React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import { getElementStatsByIdentifier } from "../core/store/element-stats/reducers";
import { IElementStat } from "../core/store/element-stats/types";
import { getElementsById } from "../core/store/elements/reducers";
import { showElementSummary } from "../core/store/elements/thunks";
import { IElementsById } from "../core/store/elements/types";
import {
  getCurrentEvent,
  getEventsById,
  getNextEvent,
} from "../core/store/events/reducers";
import { IEvent, IEventsById } from "../core/store/events/types";
import { getGroupedFixturesByEvent } from "../core/store/fixtures/reducers";
import { fetchFixtures } from "../core/store/fixtures/thunks";
import {
  IFixtureStat,
  IFixtureStats,
  IGroupedFixturesByEvent,
} from "../core/store/fixtures/types";
import { formatRawAsISO, formatRawAsLocalI18n } from "../core/utils/datetime";
import i18n, { dateLocales } from "../i18n";
import { ReactComponent as Logo } from "../img/logo.svg";
import Button from "./Button";
import ButtonLink from "./ButtonLink";
import Fixture from "./Fixture";
import { Pager, PagerButton, PagerButtonNext } from "./Pager";
import { BoldSubHeading } from "./SubHeading";
import { BoxWrap, PrimaryGradient } from "./Utils";

const FixtureHeader = styled.header``;

const Heading = styled.h3`
  ${PrimaryGradient}
  padding: ${(props) => props.theme.space[3]};
  display: flex;
  align-items: center;
  margin: auto 0;
  border-top-right-radius: ${(props) => props.theme.radii[1]};
  border-top-left-radius: ${(props) => props.theme.radii[1]};
  color: white;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizes[3]};
  font-weight: 500;
  text-transform: uppercase;
  ${i18n.language === "tr" &&
  css`
    text-transform: capitalize;
  `}
`;

const HeadingText = styled.span`
  margin-left: ${(props) => props.theme.space[2]};
  line-height: 1;
`;

const Deadline = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 40%;
    margin: -4.8rem auto 0;
  }
`;

const EventName = styled.span`
  margin: 0 0.6rem 0 1.5rem;
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 3.5rem;
  font-weight: 500;
  text-transform: uppercase;

  ${i18n.language === "he" &&
  css`
    margin: 0 1.5rem 0 0.6rem;
  `}
`;

const DeadlineTime = styled.time`
  margin-right: ${({ theme }) => theme.space[3]};
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSizes[1]};

  ${i18n.language === "he" &&
  css`
    margin-right: 0;
    margin-left: ${({ theme }) => theme.space[3]};
  `}
`;

const Note = styled.p`
  color: #6c6c6c;
  text-align: center;
`;

const FixtureList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const FixtureItem = styled.li`
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
  list-style: none;
`;

const FixtureButton = styled.button<IStyledFixtureStatsProps>`
  width: 100%;
  padding: 0;
  border: 0;
  background-color: ${(props) =>
    props.isOpen ? props.theme.colors.yellow : "transparent"};
  text-align: left;
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.theme.colors.yellow};
  }
`;

const FixtureStatsWrap = styled.div<IStyledFixtureStatsProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  padding: ${({ theme }) => `${theme.space[4]} 5%`};

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding-right: 15%;
    padding-left: 15%;
  }
`;

const FixtureStatsOuterList = styled.ul`
  list-style: none;
  padding: 0;
`;

const FixtureStat = styled.li`
  margin-bottom: ${({ theme }) => theme.space[4]};
  box-shadow: 1px 2px 10px 2px ${rgba("black", 0.1)};
  background-color: white;
`;

const FixtureStatHeading = styled.h5`
  ${PrimaryGradient}
  margin: 0;
  padding: 0.3rem ${({ theme }) => theme.space[2]};
  color: white;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: bold;
  text-align: center;
`;

const FixtureStatBody = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.space[1]};
  padding-bottom: ${({ theme }) => theme.space[1]};
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.yellow};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const FixtureStatList = styled.ul`
  flex: 1;
  padding-right: ${({ theme }) => theme.space[2]};
  padding-left: ${({ theme }) => theme.space[2]};

  :first-child {
    border-right: 1px solid #e2e2e2;
    text-align: right;
  }
`;

const FixtureStatItem = styled.li`
  padding-top: ${({ theme }) => theme.space[1]};
  padding-bottom: ${({ theme }) => theme.space[1]};
  border-bottom: 1px solid #e2e2e2;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  list-style: none;

  :last-child {
    border-bottom: 0;
  }
`;

const ElementButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  color: black;
  font-size: ${({ theme }) => theme.fontSizes[2]};

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface IFixtureStatsForTeamProps {
  elementsById: IElementsById;
  showElementDialog: (elementId: number) => void;
  stats: IFixtureStat[];
}

const FixtureStatsForTeam: React.FC<IFixtureStatsForTeamProps> = ({
  elementsById,
  showElementDialog,
  stats,
}) => (
  <FixtureStatList>
    {stats
      .filter((fs) => elementsById[fs.element])
      .map((fs) => (
        <FixtureStatItem key={fs.element}>
          <ElementButton onClick={(e: any) => showElementDialog(fs.element)}>
            {elementsById[fs.element].web_name}
          </ElementButton>{" "}
          ({fs.value})
        </FixtureStatItem>
      ))}
  </FixtureStatList>
);

interface IFixtureStatsProps {
  elementsById: IElementsById;
  showElementDialog: (elementId: number) => void;
  stats: IFixtureStats[];
  statsByIdentifier: Record<string, IElementStat>;
}

interface IStyledFixtureStatsProps {
  isOpen?: boolean;
}

type FixtureStatProps = IFixtureStatsProps & IStyledFixtureStatsProps;

const FixtureStats: React.FC<FixtureStatProps> = ({
  elementsById,
  isOpen = false,
  showElementDialog,
  stats,
  statsByIdentifier,
}) => {
  // Our render stats should contain only stats with data and a maximum
  // of 5 per team of BPS
  const renderStats = stats
    .filter((stat) => stat.h.length || stat.a.length)
    .map((stat) =>
      stat.identifier === "bps"
        ? {
            identifier: stat.identifier,
            h: stat.h.slice(0, 5),
            a: stat.a.slice(0, 5),
          }
        : stat
    );
  return (
    <FixtureStatsWrap isOpen={isOpen}>
      <FixtureStatsOuterList>
        {renderStats.map((stat) => (
          <FixtureStat key={stat.identifier}>
            <FixtureStatHeading>
              {statsByIdentifier[stat.identifier].label}
            </FixtureStatHeading>
            <FixtureStatBody>
              <FixtureStatsForTeam
                stats={stat.a}
                elementsById={elementsById}
                showElementDialog={showElementDialog}
              />
              <FixtureStatsForTeam
                stats={stat.h}
                elementsById={elementsById}
                showElementDialog={showElementDialog}
              />
            </FixtureStatBody>
          </FixtureStat>
        ))}
      </FixtureStatsOuterList>
    </FixtureStatsWrap>
  );
};

interface IOwnProps {
  eventId?: number;
  useLinks?: boolean;
}

interface IPropsFromDispatch {
  fetchFixturesForEvent: (eventId: number) => void;
  showElementDialog: (elementId: number) => void;
}

interface IPropsFromState {
  currentEvent: IEvent | null;
  elementsById: IElementsById;
  eventsById: IEventsById;
  groupedFixturesByEvent: IGroupedFixturesByEvent;
  nextEvent: IEvent | null;
  statsByIdentifier: Record<string, IElementStat>;
}

type Props = IOwnProps & WithTranslation & IPropsFromDispatch & IPropsFromState;

interface IState {
  eventId: number;
  isOpen: Record<string, boolean>;
}

class Fixtures extends React.Component<Props, IState> {
  public constructor(props: Props) {
    super(props);
    let eventId = 0;
    if (props.eventId) {
      eventId = props.eventId;
    } else if (props.nextEvent) {
      eventId = props.nextEvent.id;
    } else if (props.currentEvent) {
      eventId = props.currentEvent.id;
    }
    this.state = { eventId, isOpen: {} };
  }

  public componentDidMount() {
    this.props.fetchFixturesForEvent(this.state.eventId);
  }

  public changeEvent = (eventId: number) => {
    this.props.fetchFixturesForEvent(eventId);
    this.setState({ eventId });
  };

  public toggle = (fixtureId: number) => {
    this.setState({
      isOpen: {
        ...this.state.isOpen,
        [fixtureId]: !this.state.isOpen[fixtureId],
      },
    });
  };

  public render() {
    const {
      elementsById,
      eventsById,
      groupedFixturesByEvent,
      i18n,
      showElementDialog,
      statsByIdentifier,
      t,
      useLinks = false,
    } = this.props;
    const { eventId } = this.state;
    const event = eventsById[eventId];
    if (!event) {
      return null;
    }

    const eventFixtureGroups = groupedFixturesByEvent[eventId] || [];
    return (
      <section>
        <BoxWrap>
          <FixtureHeader>
            <Heading>
              <Logo />
              <HeadingText>{t("fixtures.title", "Schedule")}</HeadingText>
            </Heading>
            <Box m={2}>
              <Pager>
                {eventsById[eventId - 1] && (
                  <PagerButton>
                    {useLinks ? (
                      <ButtonLink
                        to={`/fixtures/${eventId - 1}`}
                        onClick={() => this.changeEvent(eventId - 1)}
                        fullwidth="true"
                      >
                        {t("fixtures.previous", "Previous")}
                      </ButtonLink>
                    ) : (
                      <Button
                        onClick={() => this.changeEvent(eventId - 1)}
                        fullwidth="true"
                      >
                        {" "}
                        {t("fixtures.previous", "Previous")}
                      </Button>
                    )}
                  </PagerButton>
                )}
                {eventsById[eventId + 1] && (
                  <PagerButtonNext>
                    {useLinks ? (
                      <ButtonLink
                        to={`/fixtures/${eventId + 1}`}
                        onClick={() => this.changeEvent(eventId + 1)}
                        fullwidth="true"
                      >
                        {t("fixtures.next", "Next")}
                      </ButtonLink>
                    ) : (
                      <Button
                        onClick={() => this.changeEvent(eventId + 1)}
                        fullwidth="true"
                      >
                        {t("fixtures.next", "Next")}
                      </Button>
                    )}
                  </PagerButtonNext>
                )}
              </Pager>
            </Box>
            <Deadline>
              <EventName>{event.name}: </EventName>
              <DeadlineTime dateTime={formatRawAsISO(event.deadline_time)}>
                {formatRawAsLocalI18n(
                  event.deadline_time,
                  dateLocales[i18n.language]
                )}
              </DeadlineTime>
            </Deadline>
          </FixtureHeader>
          <Note>
            <Trans i18nKey="fixtures.localTimes">
              All times are shown in your <strong>local time</strong>
            </Trans>
          </Note>
          {/* {event.id >= 39 && event.id <= 43 && (
            <Box pb={1}>
              <Note>
                <strong>
                  {t(
                    "fixtures.TBD",
                    "Schedule to be announced at a later date"
                  )}
                </strong>
              </Note>
            </Box>
          )} */}
          {eventFixtureGroups.map((group) => (
            <div key={group.date.toISOString()}>
              <BoldSubHeading as="h4" align="center">
                <time dateTime={group.date.toISOString()}>
                  {format(group.date, "EEEE d MMMM yyyy")}
                </time>
              </BoldSubHeading>
              <FixtureList>
                {group.fixtures.map((f) => (
                  <React.Fragment key={f.id}>
                    {f.started && f.team_h_score !== null ? (
                      <FixtureItem>
                        <FixtureButton
                          onClick={() => this.toggle(f.id)}
                          isOpen={this.state.isOpen[f.id]}
                        >
                          <Fixture fixture={f} />
                        </FixtureButton>
                        <FixtureStats
                          stats={f.stats}
                          elementsById={elementsById}
                          showElementDialog={showElementDialog}
                          statsByIdentifier={statsByIdentifier}
                          isOpen={this.state.isOpen[f.id]}
                        />
                      </FixtureItem>
                    ) : (
                      <FixtureItem>
                        <Fixture fixture={f} />
                      </FixtureItem>
                    )}
                  </React.Fragment>
                ))}
              </FixtureList>
            </div>
          ))}
        </BoxWrap>
      </section>
    );
  }
}

export { Fixtures as FixturesTest };

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => ({
  currentEvent: getCurrentEvent(state),
  elementsById: getElementsById(state),
  eventsById: getEventsById(state),
  groupedFixturesByEvent: getGroupedFixturesByEvent(state),
  nextEvent: getNextEvent(state),
  statsByIdentifier: getElementStatsByIdentifier(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchFixturesForEvent: (eventId) => dispatch(fetchFixtures(eventId)),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Fixtures)
);

import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getPlayerData } from "../core/store/player/reducers";
import { showForgotPassword, showLogin, showSignup } from "../utils/widget";
import Button from "./Button";
import Copy from "./Copy";
import LinkButton from "./LinkButton";

const mapState = (state: RootState) => ({
  player: getPlayerData(state),
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

const LoginWrap = styled.div`
  margin-bottom: ${(props) => props.theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    width: calc(100% / 3);
  }
`;

const LoginButtonWrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-bottom: 0;
  }
`;

const LoginLinkButton = styled(LinkButton)`
  color: ${(props) => props.theme.colors.offBlack};
  font-weight: 400;
  padding: 1rem 0;
`;

const Login: React.FC<PropsFromRedux> = ({ player }) => {
  const { t } = useTranslation();
  if (player && player.id) {
    return (
      <Copy>
        <p>
          {t(
            "login.loggedInAs",
            "You are logged in as {{firstName}} {{lastName}}",
            {
              firstName: player.first_name,
              lastName: player.last_name,
            }
          )}
        </p>
      </Copy>
    );
  }

  return (
    <LoginWrap>
      <LoginButtonWrap>
        <Button fullwidth="true" onClick={() => showLogin()}>
          {t("home.signInButton", "Sign In")}
        </Button>
      </LoginButtonWrap>
      <LoginLinkButton onClick={showForgotPassword}>
        {t("home.forgotPassword", "Forgot your password?")}
      </LoginLinkButton>
      <LoginButtonWrap>
        <Button fullwidth="true" onClick={() => showSignup()}>
          {t("home.signUpButton", "Create Account")}
        </Button>
      </LoginButtonWrap>
    </LoginWrap>
  );
};

export default connector(Login);

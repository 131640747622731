import { navigate, RouteComponentProps } from "@reach/router";
import sortBy from "lodash/sortBy";
import values from "lodash/values";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
//import styled from "styled-components/macro";
import { IError, RootState, ThunkDispatch } from "../../core/store";
import { bootstrap } from "../../core/store/bootstrap/thunks";
import {
  getPlayerData,
  getPlayerUpdateError,
  getRegisterError,
} from "../../core/store/player/reducers";
import { playerUpdate, register } from "../../core/store/player/thunks";
import { getRegions } from "../../core/store/regions/reducers";
import { IRegion } from "../../core/store/regions/types";
import { IPlayer } from "../../types";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import { CheckboxField } from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import styled from "styled-components";

const Border = styled.div`
  margin: ${(props) => props.theme.space[2]};
  padding: ${(props) => props.theme.space[2]};
  border: 3px solid #e6e6e6;
  border-radius: ${(props) => props.theme.radii[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin: ${({ theme }) => `${theme.space[6]} ${theme.space[2]}`};
  }
`;

interface IPropsFromState {
  player: IPlayer | null;
  regions: IRegion[];
  registerError: IError | null;
  updateError: IError | null;
}

interface IPropsFromDispatch {
  doBootstrap: () => Promise<void>;
  registerPlayer: (data: {}) => Promise<void>;
  updatePlayer: (data: {}) => Promise<void>;
}

type Props = RouteComponentProps &
  WithTranslation &
  IPropsFromState &
  IPropsFromDispatch;

interface IState {
  firstName: string;
  lastName: string;
  errorFName: string;
  errorLName: string;
  ageVerified: boolean;
  emailUpdate: boolean;
}

class Register extends React.Component<Props, IState> {
  public errorsTxt: { [key: string]: string } = {
    EFName: this.props.t("register.errors.fname", "First name is required"),
    ELName: this.props.t("register.errors.lname", "Last name is required"),
  };

  constructor(props: Props) {
    super(props);
    const player = props.player;
    this.state = {
      emailUpdate: player ? player.email_update : false,
      errorFName: "",
      errorLName: "",
      firstName: player ? player.first_name : "",
      lastName: player ? player.last_name : "",
      ageVerified: true,
      // ageVerified: false,
    };
  }

  public getMode = () => {
    if (!this.props.player) {
      return "register";
    }
    return this.props.player.dirty ? "confirm" : "update";
  };

  public getUpdateEmail = () => {
    if (this.props.player?.email_update) {
      return true;
    }
    return false;
  };

  public getRequiredFields(): Array<keyof IState> {
    switch (this.getMode()) {
      case "register":
        return ["firstName", "lastName", "ageVerified"];
      case "confirm":
        return ["firstName", "lastName", "ageVerified"];
      case "update":
        return ["firstName", "lastName"];
    }
  }

  public apiDataFromState() {
    switch (this.getMode()) {
      case "register":
        return {};
      case "confirm":
        return {
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          // age_verified: this.state.ageVerified,
          age_verified: true,
          email_update: this.state.emailUpdate,
        };
      case "update":
        return {
          email_update: this.state.emailUpdate,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          age_verified: true,
        };
    }
  }

  public handleFirstNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ firstName: e.currentTarget.value });

  public handleLastNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ lastName: e.currentTarget.value });

  public handleageVerified = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ ageVerified: e.currentTarget.checked ? true : false });

  public handleemailUpdateChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ emailUpdate: e.currentTarget.checked ? true : false });

  public handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const mode = this.getMode();
    if (mode === "register") {
      try {
        await this.props.registerPlayer(this.apiDataFromState());
      } catch (e) {
        window.scrollTo(0, 0); // To see error if failure ...
        return;
      }
      await this.props.doBootstrap();
      navigate("/squad-selection");
    } else if (mode === "confirm") {
      try {
        await this.props.updatePlayer(this.apiDataFromState());
      } catch (e) {
        window.scrollTo(0, 0); // To see error if failure ...
        return;
      }
      await this.props.doBootstrap();
      navigate("/squad-selection");
    } else if (mode === "update") {
      try {
        await this.props.updatePlayer(this.apiDataFromState());
      } catch (e) {
        window.scrollTo(0, 0); // To see error if failure ...
        return;
      }
      await this.props.doBootstrap();
      // Maybe this should be back to my team, maybe our LoggedIn component
      navigate("/");
    }
  };

  public getIntRegions() {
    const regs = this.props.regions;
    return sortBy(regs, ["name"]);
  }

  public handleFirstnameBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      this.setState({ errorFName: this.errorsTxt.EFName });
    } else {
      this.setState({ errorFName: "" });
    }
  };

  public handleLastnameBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      this.setState({ errorLName: this.errorsTxt.ELName });
    } else {
      this.setState({ errorLName: "" });
    }
  };

  public handleServerErrors = () => {
    const errors =
      this.getMode() === "register"
        ? this.props.registerError
        : this.props.updateError;
    const formattedErrors = [];
    console.log(errors);
    if (errors) {
      if (
        errors.badRequest &&
        errors.badRequest.first_name &&
        errors.badRequest.first_name[0].code === "blank"
      ) {
        formattedErrors.push(this.errorsTxt.EFName);
      }
      if (
        errors.badRequest &&
        errors.badRequest.last_name &&
        errors.badRequest.last_name[0].code === "blank"
      ) {
        formattedErrors.push(this.errorsTxt.ELName);
      }
      return formattedErrors;
    }
  };

  public render() {
    const { t } = this.props;
    const mode = this.getMode();
    const title = {
      register: t("register.title.registration", "Registration"),
      confirm: t("register.title.confirm", "Confirm your details."),
      update: t("register.title.update", "Stay Updated"),
    };
    const buttonText = {
      register: t("register.label.register", "Register"),
      confirm: t("register.label.confirm", "Submit"),
      update: t("register.label.update", "Submit"),
    };
    // const disabled = this.getRequiredFields().some((e) => !this.state[e]);
    const errors = this.handleServerErrors();
    const eUpdate = this.getUpdateEmail();

    return (
      <Wrapper>
        <Main>
          <Border>
            <Box my={5} mx={2}>
              <h2>{title[mode]}</h2>
              <p>
                {t(
                  "register.required",
                  "Be the first to know when NBA Fantasy launches next season by clicking the checkbox below."
                )}
              </p>
            </Box>

            {errors && (
              <Alert type="error">{values(errors).map((r) => r)}</Alert>
            )}
            <Copy>
              <form onSubmit={this.handleSubmit}>
                {/* <Box mt={2}>
                  <InputField
                    id="ismRegisterFirstName"
                    hasErrors={!!this.state.errorFName}
                    label={t("register.label.fName", "First Name")}
                    value={this.state.firstName}
                    onChange={this.handleFirstNameChange}
                    onBlur={this.handleFirstnameBlur}
                  />
                  {this.state.errorFName && (
                    <FieldFeedback>{this.state.errorFName}</FieldFeedback>
                  )}
                </Box>
                <Box mt={2}>
                  <InputField
                    id="ismRegisterLastName"
                    label={t("register.label.lName", "Last Name")}
                    hasErrors={!!this.state.errorLName}
                    value={this.state.lastName}
                    onChange={this.handleLastNameChange}
                    onBlur={this.handleLastnameBlur}
                  />
                  {this.state.errorLName && (
                    <FieldFeedback>{this.state.errorLName}</FieldFeedback>
                  )}
                </Box> */}
                {/* {mode === "confirm" && (
                  <Box mt={2}>
                    <CheckboxField
                      id="ageVerified"
                      name="ageVerified"
                      label={t("register.ageVerified", "I am over 16")}
                      onChange={this.handleageVerified}
                      checked={this.state.ageVerified}
                    />
                  </Box>
                )} */}
                <Box my={3}>
                  <CheckboxField
                    checked={this.state.emailUpdate}
                    id="ismRegisteremailUpdate"
                    label={t(
                      "register.label.emailClient",
                      "Please use my personal information for the NBA to send me messages and advertisements about products and initiatives of the NBA and NBA partners."
                    )}
                    onChange={this.handleemailUpdateChange}
                  />
                </Box>
                <Box mt={3} mb={8}>
                  {/* <Button type="submit" disabled={disabled}> */}
                  <Button type="submit">{buttonText[mode]}</Button>
                </Box>
                <Box mt={5} mb={6} fontSize="1.5rem">
                  {eUpdate && (
                    <Alert type={"success"}>
                      <p>
                        {t(
                          "register.label.confirmationTrue",
                          "You are opted in to receive email updates for next season's game."
                        )}
                      </p>
                    </Alert>
                  )}
                  {eUpdate || (
                    <Alert type="error">
                      <p>
                        {t(
                          "register.label.confirmationFalse",
                          "You are opted out of receiving email updates."
                        )}
                      </p>
                    </Alert>
                  )}
                </Box>
              </form>
            </Copy>
          </Border>
        </Main>
      </Wrapper>
    );
  }
}

export { Register as RegisterTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  player: getPlayerData(state) as IPlayer | null,
  regions: getRegions(state),
  registerError: getRegisterError(state),
  updateError: getPlayerUpdateError(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  doBootstrap: () => dispatch(bootstrap()),
  registerPlayer: (data) => dispatch(register(data)),
  updatePlayer: (data) => dispatch(playerUpdate(data)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);

import { rgba } from "polished";
import styled, { css } from "styled-components/macro";
import { SubStatus } from "../core/store/my-team/types";

export const PitchRow = styled.div`
  display: flex;
  justify-content: center;
`;

export const PitchUnit = styled.div`
  flex: 0 0 calc(100% / 3);
  min-height: 7.2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    min-height: 9.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-height: 11.6rem;
  }
`;

export const PitchElementWrap = styled.div<IWrapStyleProps>`
  ${(props) =>
    (props.variant === "instigator" || props.variant === "target") &&
    css`
      transition: all 0.15s;
      box-shadow: 0 0 0.6rem 0 rgba(0, 0, 0, 0.4);
    `}

  ${(props) =>
    props.variant === "instigator" &&
    css`
      background-color: ${rgba("yellow", 0.6)};
    `}

  ${(props) =>
    props.variant === "target" &&
    css`
      background-color: ${rgba("#f60", 0.6)};
    `}

  ${(props) =>
    props.variant === "invalid" &&
    css`
      opacity: 0.6;
    `}
`;

export const PitchElementOuter = styled.div`
  position: relative;
  width: 11.7rem;
  margin: auto;
  padding: 2.5rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 15.5rem;
  }
`;

export const PitchElementInner = styled.div<IPitchElementStyleProps>`
  border-radius: ${(props) => props.theme.radii[1]};
  box-shadow: 1px 2px 10px 2px ${rgba("black", 0.1)};
  background-color: #fff;
  opacity: ${(props) => (props.isRemoved ? "0.8" : "1")};
`;

export const Controls = styled.div`
  display: block;
`;

export const PrimaryControl = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: absolute;
    top: 0;
    right: 10px;
  }
`;

export const InfoControl = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
`;

interface IWrapStyleProps {
  variant?: SubStatus;
}

interface IPitchElementStyleProps {
  elementType?: number;
  isRemoved?: boolean;
}

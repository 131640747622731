export type SITE_IDS =
  | "main_us"
  | "main_en"
  | "main_fr"
  | "main_es"
  | "main_it"
  | "main_de"
  | "main_el"
  | "main_he"
  | "main_br"
  | "main_tr"
  | "sport5"
  | "sky_italia";

const sites: Record<string, SITE_IDS> = {
  "nba.app": "main_en",
  "nbafantasy.nba.com": "main_en",
  "nba.staging.ismgames.com": "main_en",
  "en.nba.app": "main_us",
  "en.nbafantasy.nba.com": "main_us",
  "en.nba.staging.ismgames.com": "main_us",
  "fr.nba.app": "main_fr",
  "fr.nbafantasy.nba.com": "main_fr",
  "fr.nba.staging.ismgames.com": "main_fr",
  "es.nba.app": "main_es",
  "es.nbafantasy.nba.com": "main_es",
  "es.nba.staging.ismgames.com": "main_es",
  "it.nba.app": "main_it",
  "it.nbafantasy.nba.com": "main_it",
  "it.nba.staging.ismgames.com": "main_it",
  "de.nba.app": "main_de",
  "de.nbafantasy.nba.com": "main_de",
  "de.nba.staging.ismgames.com": "main_de",
  "gr.nba.app": "main_el",
  "gr.nbafantasy.nba.com": "main_el",
  "gr.nba.staging.ismgames.com": "main_el",
  "he.nba.app": "main_he",
  "he.nbafantasy.nba.com": "main_he",
  "he.nba.staging.ismgames.com": "main_he",
  "br.nba.app": "main_br",
  "br.nbafantasy.nba.com": "main_br",
  "br.nba.staging.ismgames.com": "main_br",
  "tr.nba.app": "main_tr",
  "tr.nbafantasy.nba.com": "main_tr",
  "tr.nba.staging.ismgames.com": "main_tr",
  "sport5.nba.app": "sport5",
  "nbafantasy.sport5.co.il": "sport5",
  "sport5.nba.staging.ismgames.com": "sport5",
  "skyit.nba.app": "sky_italia",
  "skyit.nba.staging.ismgames.com": "sky_italia",
  "nbafantasy.sky.it": "sky_italia",
};

export const getSite = () => sites[window.location.hostname] || "";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ThunkDispatch } from "../../core/store";
import { getActiveOrProposedTeamChipName } from "../../core/store/chips/reducers";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import {
  getElement,
  getFixturesWithBlanks,
} from "../../core/store/elements/reducers";
import { getElementSummary } from "../../core/store/elements/thunks";
import {
  IElementFixture,
  IElementFixtureBlank,
} from "../../core/store/elements/types";
import { IPickProposed } from "../../core/store/my-team/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { integerToDecimal } from "../../utils/decimal";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementCell, ElementRow } from "../ElementTable";
import FixtureForElement from "../FixtureForElement";
import TeamElementIcon from "./TeamElementIcon";

interface IMyTeamRowProps {
  pick: IPickProposed;
  elementId: number;
  renderElementMenu: (index: number) => void;
}

const MyTeamRow: React.FC<IMyTeamRowProps> = ({
  pick,
  elementId,
  renderElementMenu,
}) => {
  const chipInPlayName = useSelector(getActiveOrProposedTeamChipName);
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );
  const fixtures = useSelector(
    (state: RootState) =>
      getFixturesWithBlanks(state, elementId) as Array<
        IElementFixture | IElementFixtureBlank
      >
  );
  const elementTypesById = useSelector(getElementTypesById);
  const teamsById = useSelector(getTeamsById);

  const reduxDispatch = useDispatch<ThunkDispatch>();

  useEffect(() => {
    element && reduxDispatch(getElementSummary(element.id));
  }, [element, reduxDispatch]);

  if (!element) {
    return null;
  }

  const firstThreeFixtures = fixtures.slice(0, 3);

  return (
    <ElementRow variant={pick.subStatus}>
      <td>
        <ElementDialogButton elementId={element.id} variant="list" />
      </td>
      <td>
        <TeamElementIcon pick={pick} chipName={chipInPlayName} />
      </td>
      <ElementCell>
        <ElementInTable
          renderElementMenu={() => renderElementMenu(pick.element)}
          elementId={element.id}
        />
      </ElementCell>
      <td>{elementTypesById[element.element_type].singular_name_short}</td>
      <td>{integerToDecimal(Number(element.form), 10)}</td>
      <td>{integerToDecimal(element.event_points, 10)}</td>
      <td>{integerToDecimal(element.total_points, 10)}</td>
      {firstThreeFixtures.map((fixture) =>
        fixture.code ? (
          <td key={fixture.code}>
            <FixtureForElement
              element={element}
              fixture={fixture}
              teamsById={teamsById}
            />
          </td>
        ) : (
          <td key={fixture.event!}>{"-"}</td>
        )
      )}
    </ElementRow>
  );
};

export default MyTeamRow;
